import chevron from '../../assets/images/chevron.svg'
import burger from '../../assets/images/burger.svg'
import Menu from './Menu'
import ServersCountries from '../Servers/ServersAll'
import appHeight from '../../helpers/iphoneHeight'

import { useState, createContext, useTransition, useEffect } from 'react'

interface serverContextType {
    currentServer: {
        city: string
        iso:number
    }
    // eslint-disable-next-line
    setCurrentServer: (value: { city: string, iso:number }) => void
    // eslint-disable-next-line
    setElectedServer: (value: {
        city: string
        lat: number
        long: number
    }) => void
}

export const serverContext = createContext<serverContextType>({
    currentServer: {
        city: '',
        iso: 0,
    },
    setCurrentServer: () => {
        return
    },
    setElectedServer: () => {
        return
    },
})

type HomeHeaderProps = {
    disconnect: boolean
    // eslint-disable-next-line
    setElectedServer: (value: {
        city: string
        lat: number
        long: number
    }) => void
}

function HomeHeader({ disconnect, setElectedServer }: HomeHeaderProps) {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [serversOpen, setServersOpen] = useState(false)
    // eslint-disable-next-line
    const [isPending, startTransition] = useTransition()
    const [currentServer, setCurrentServer] = useState<{
        city: string
        iso: number
    }>({
        city: '',
        iso: 0,
    })

    const menuOpenHandler = () => {
        startTransition(() => {
            setServersOpen(true)
        })

        if (isMenuOpen) {
            setMenuOpen(false)
        }
    }

    useEffect(() => {
        appHeight('--body--overflow', 'hidden')

        return () => {
            appHeight('--body--overflow', 'visible')
        }
    }, [])

    return (
        <header className={disconnect ? 'header disconnect' : 'header'}>
            {disconnect && (
                <div className='header__disconnect'>No internet connection</div>
            )}
            <Menu menuOpen={setMenuOpen} isMenuOpen={isMenuOpen} />
            <serverContext.Provider
                value={{ currentServer, setCurrentServer, setElectedServer }}>
                <ServersCountries
                    serversOpen={serversOpen}
                    setServersOpen={setServersOpen}
                />
            </serverContext.Provider>
            <div className='header__inner'>
                <button
                    className='header__burger'
                    onClick={() => setMenuOpen(true)}>
                    <img
                        src={burger}
                        alt='schevron down'
                        className='header__icon'
                    />
                </button>
                <button
                    className='header__servers'
                    onClick={() => menuOpenHandler()}>
                    {currentServer.iso > 0 &&(
                        <img
                        src={require(`../../assets/images/flags/${currentServer.iso}.svg`)}
                        alt='flag'
                        className='header__flag'
                    />
                    )}
                    <span className='header__name'>
                        {currentServer.city || 'Automatic'}
                    </span>
                    <img
                        src={chevron}
                        alt='schevron down'
                        className='header__chevron'
                    />
                </button>
            </div>
        </header>
    )
}

export default HomeHeader
