import { RouterProvider } from 'react-router-dom'
import router from './router/index'
import appHeight from './helpers/iphoneHeight'

function App() {
    sessionStorage.setItem('Resent Servers', JSON.stringify([]))

    window.addEventListener('resize', () =>
        appHeight('--app-height', `${window.innerHeight}px`)
    )
    appHeight('--app-height', `${window.innerHeight}px`)

    return <RouterProvider router={router} />
}

export default App
