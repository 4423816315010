import { useState } from 'react'

type ButtonVPNProps = {
    disconnect: boolean
}
function ButtonVPN({ disconnect }: ButtonVPNProps) {
    const [isConnected, setIsConnected] = useState(false)

    const setConnectedServerHandler = () => {
        const serverConnected = localStorage.getItem('Connected Server')
        setIsConnected(!isConnected)
        if (serverConnected) {
            const server = JSON.parse(serverConnected)

            if (isConnected) {
                console.log('disconnected from server')
            } else {
                console.log('connected to:', server)
            }
        } else {
            setIsConnected(false)
        }
    }

    return (
        <button
            className={
                disconnect
                    ? 'button-vpn disconnect'
                    : isConnected
                    ? 'button-vpn connected'
                    : 'button-vpn '
            }
            onClick={() => setConnectedServerHandler()}>
            {disconnect
                ? 'DISCONNECT'
                : isConnected
                ? 'DISABLE VPN'
                : 'ENABLE VPN'}
        </button>
    )
}

export default ButtonVPN
