import ButtonVPN from '../components/ButtonVPN/ButtonVPN'
import InfoScreen from '../components/FirstRun/InfoScreen'
import HomeHeader from '../components/Header/HomeHeader'
import disconnectSVG from '../assets/images/disconnect.png'
import { useEffect, useState } from 'react'
import DynamicMap from '../components/DynamicMap/DynamicMap'

type ConnectedServerType = {
    city: string
    lat: number
    long: number
}

function Home() {
    const [disconnect, setDisconnect] = useState(false)
    const [showInfoScreen, setShowInfoScreen] = useState(false)
    const [electedServer, setElectedServer] = useState<ConnectedServerType>({
        city: '',
        lat: 0,
        long: 0,
    })

    useEffect(() => {
        const firstRun = localStorage.getItem('First Run')

        if (firstRun) {
            setShowInfoScreen(false)
        } else {
            setShowInfoScreen(true)
        }

        // disconnect imitation

        if (disconnect) {
            setDisconnect(true)
        }
    }, [])

    return (
        <section className='home container'>
            {showInfoScreen ? (
                <InfoScreen setShowInfoScreen={setShowInfoScreen} />
            ) : (
                <div className='home__inner'>
                    <HomeHeader
                        disconnect={disconnect}
                        setElectedServer={setElectedServer}
                    />
                    <DynamicMap connectedServer={electedServer} />
                    <ButtonVPN
                        disconnect={disconnect}
                    />
                </div>
            )}

            {disconnect && (
                <div className='home-noconnection'>
                    <img
                        src={disconnectSVG}
                        alt='disconnect'
                        className='home-noconnection__icon'
                    />
                    <h3 className='home-noconnection__title'>NO CONNECTION</h3>
                    <div className='home-noconnection__subtitle'>
                        You will be connected automatically once connection is
                        back
                    </div>
                </div>
            )}
        </section>
    )
}

export default Home
