import { useEffect, useRef, useState, useTransition } from 'react'
import { calculateCoords } from '../../helpers/mapHelpers'
import map from '../../assets/images/map.svg'
import mapMarker from '../../assets/images/map-marker.svg'
type DynamicMapProps = {
    connectedServer: {
        city: string
        lat: number
        long: number
    }
}
function DynamicMap({ connectedServer }: DynamicMapProps) {
    const mapImage = useRef<HTMLDivElement>(null)
    const mapContainer = useRef<HTMLDivElement>(null)
    const pointer = useRef<HTMLDivElement>(null)
    const [posX, setPosX] = useState<number>(0)
    const [posY, setPosY] = useState<number>(0)
    // eslint-disable-next-line
    const [isPending, startTransition] = useTransition()

    const connectedServerUpdater = () => {
        if (mapImage.current && mapContainer.current && pointer.current) {
            mapContainer.current.style.transform = `translate(-50%, -50%) scale(3)`
            pointer.current.style.opacity = '0'

            const mapHeight = mapImage.current.clientHeight
            const mapWidth = mapImage.current.clientWidth

            const coords = calculateCoords(
                connectedServer.lat, //server coords from json
                connectedServer.long, //server coords from json
                mapWidth,
                mapHeight
            )

            const x = coords.x
            const y = coords.y

            if (connectedServer.lat !== 0) {
                startTransition(() => {
                    setPosX(x)
                    setPosY(y)
                })

                setTimeout(() => {
                    if (mapContainer.current && pointer.current) {
                        pointer.current.style.opacity = '1'
                        mapContainer.current.style.transform = `translate(-50%, -50%) scale(3.5)`
                    }
                }, 500)
            }
        }
    }

    useEffect(() => {
        connectedServerUpdater()
    }, [connectedServer])
    return (
        <div className='map'>
            <div ref={pointer} className='map__pointer'>
                <img className='map__marker' src={mapMarker} alt="map marker" />
            </div>
            <div className='map__inner' ref={mapContainer}>
                <div
                    ref={mapImage}
                    className='map__image'
                    style={{
                        backgroundImage: `url(${map})`,
                        top: `${posY}px`,
                        left: `${posX}px`,
                    }}></div>
            </div>
        </div>
    )
}

export default DynamicMap
